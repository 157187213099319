<template>
  <!-- layout-container lesson 10 -->
  <div class="lesson42-container">
    <div class="display-page" v-if="pageType === 0">
      <template
        v-for="(item, key, index) in componentsList"
        class="display-page"
      >
        <keep-alive :key="index + 'keep'">
          <transition name="slide-fade" :key="index">
            <component :key="index" :is="item" v-if="index + 1 === pageNum">
            </component>
          </transition>
        </keep-alive>
      </template>
    </div>
    <div class="display-page" v-if="pageType === 1">
      <SyllabusPageB />
    </div>
    <div class="display-page test" v-if="pageType === 2">
      <WhiteBoard />
    </div>
    <div class="whiteBoard-box" v-if="globalWhiteB">
      <!-- 全局白板部分 -->
      <WhiteBoard :canvasHeight="cvHeight" :canvasWidth="cvWidth" />
    </div>
  </div>
</template>
<script>
import { lessonMixin } from "@/mixins/lessonDefault.js";
import SyllabusPageB from "./3_SyllabusPage_B.vue";
import WhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue";

import LessonPage from "./1_Lesson.vue";
import RankMapPageOne from "./2_RankMapPageOne.vue";
import TargetPage from "./3_Target.vue";
import RocketGame from "./4_RocketGame.vue";
import DragGamePageOne from "./5_DragGameOne.vue";
import DragGamePageTwo from "./6_DragGameTwo.vue";
import PracticePageOne from "./7_PracticePageOne.vue";
import MoveGame from "./8_MoveGame.vue";
import SpeakingPage from "./9_SpeakingPage.vue";
import SpeakingPageTwo from "./10_SpeakingPageTwo.vue";
import RecognitionPage_henggou from "./11_RecognitionPage_henggou.vue";
import PracticePageOne_henggou from "./12_PracticePageOne_henggou.vue";
import PracticePageTwo_henggou from "./13_PracticePageTwo_henggou.vue";
import writePage_henggou from "./14_writePage_henggou.vue";
import PracticeGame from "./15_PracticeGame.vue";
import PracticePageTwo from "./16_PracticePageTwo.vue";
import WriteGame_huan from "./17_WriteGame_huan.vue";
import WriteGame_ma from "./18_WriteGame_ma.vue";
import LearnReview from "./19_LearnReview.vue";
import RankMapPageTwo from "./20_RankMapPageTwo.vue";
import ByePage from "./21_ByePage.vue";
export default {
  name: "Lesson42",
  components: {
    SyllabusPageB,
    WhiteBoard,
    LessonPage,
    RankMapPageOne,
    TargetPage,
    RocketGame,
    DragGamePageOne,
    DragGamePageTwo,
    PracticePageOne,
    MoveGame,
    SpeakingPage,
    SpeakingPageTwo,
    RecognitionPage_henggou,
    PracticePageOne_henggou,
    PracticePageTwo_henggou,
    writePage_henggou,
    PracticeGame,
    PracticePageTwo,
    WriteGame_huan,
    WriteGame_ma,
    LearnReview,
    RankMapPageTwo,
    ByePage,
  },
  mixins: [lessonMixin],
  data() {
    return {
      pageNum: 1,
      // lesson2 中的所有page
      componentsList: {
        LessonPage,
        RankMapPageOne,
        TargetPage,
        RocketGame,
        DragGamePageOne,
        DragGamePageTwo,
        PracticePageOne,
        MoveGame,
        SpeakingPage,
        SpeakingPageTwo,
        RecognitionPage_henggou,
        PracticePageOne_henggou,
        PracticePageTwo_henggou,
        writePage_henggou,
        PracticeGame,
        PracticePageTwo,
        WriteGame_huan,
        WriteGame_ma,
        LearnReview,
        RankMapPageTwo,
        ByePage,
      },
    };
  },
};
</script>
>

<style lang="scss" scoped>
.lesson42-container {
  width: 100%;
  height: 100%;
  .whiteBoard-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    line-height: 1;
  }

  // .page-item {
  //   width: 100%;
  //   height: 100%;

  .display-page {
    width: 100%;
    height: 100%;
    // background-color: #fff;
    border-radius: 56px;
  }
  .test {
    background-color: #fff;
  }

  // }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
