<template>
  <div class="game-container">
    <DragStrokeToImgGame
      :chineseList="chineseList"
      :strokeList="strokeList"
      :bgImg="bgImg"
      :mapList="mapList"
      @handleDropEvent="handleDropEvent"
      :answersfound="answersFoundSum"
    />
  </div>
</template>

<script>
import DragStrokeToImgGame from "@/components/Course/GamePage/DragStrokeToImgGame";
import { startConfetti, playCorrectSound } from "@/utils/tools";

export default {
  data() {
    return {
      answersFoundSum: 0,
      bgImg: require("@/assets/img/16-GAMES/G21-puzzle/chapter-06-2/background-forest.svg"),
      mapList: [
        [
          { index: 1, id: 1, type: [1] },
          { index: 4, id: null, type: [] },
          { index: 7, id: 4, type: [1] },
        ],
        [
          { index: 2, id: null, type: [] },
          { index: 5, id: 3, type: [0] },
          { index: 8, id: 5, type: [0] },
        ],
        [
          { index: 3, id: 2, type: [0] },
          { index: 6, id: null, type: [] },
          { index: 9, id: 6, type: [1] },
        ],
      ],
      strokeList: [
        {
          type: 0,
          image: require("@/assets/img/16-GAMES/G21-puzzle/chapter-06-2/button-red.svg"),
        },
        {
          type: 1,
          image: require("@/assets/img/16-GAMES/G21-puzzle/chapter-06-2/button-blue.svg"),
        },
      ],
      chineseList: [
        {
          id: 1,
          imageStartURL: require("@/assets/img/16-GAMES/G21-puzzle/chapter-06-2/character-1.svg"),
          strokeImgList: [
            {
              strokeImg: require("@/assets/img/16-GAMES/G21-puzzle/chapter-06-2/character-1-stroke.svg"),
              type: 1,
              isShow: false,
            },
          ],
          strokeSum: 0,
          isShow: true,
        },
        {
          id: 2,
          imageStartURL: require("@/assets/img/16-GAMES/G21-puzzle/chapter-06-2/character-2.svg"),
          strokeImgList: [
            {
              strokeImg: require("@/assets/img/16-GAMES/G21-puzzle/chapter-06-2/character-2-stroke.svg"),
              type: 0,
              isShow: false,
            },
          ],
          strokeSum: 0,
          isShow: true,
        },
        {
          id: 3,
          imageStartURL: require("@/assets/img/16-GAMES/G21-puzzle/chapter-06-2/character-3.svg"),
          strokeImgList: [
            {
              strokeImg: require("@/assets/img/16-GAMES/G21-puzzle/chapter-06-2/character-3-stroke.svg"),
              type: 0,
              isShow: false,
            },
          ],
          strokeSum: 0,
          isShow: true,
        },
        {
          id: 4,
          imageStartURL: require("@/assets/img/16-GAMES/G21-puzzle/chapter-06-2/character-4.svg"),
          strokeImgList: [
            {
              strokeImg: require("@/assets/img/16-GAMES/G21-puzzle/chapter-06-2/character-4-stroke.svg"),
              type: 1,
              isShow: false,
            },
          ],
          strokeSum: 0,
          isShow: true,
        },
        {
          id: 5,
          imageStartURL: require("@/assets/img/16-GAMES/G21-puzzle/chapter-06-2/character-5.svg"),
          strokeImgList: [
            {
              type: 0,
              strokeImg: require("@/assets/img/16-GAMES/G21-puzzle/chapter-06-2/character-5-stroke.svg"),
              isShow: false,
            },
          ],
          strokeSum: 0,
          isShow: true,
        },
        {
          id: 6,
          imageStartURL: require("@/assets/img/16-GAMES/G21-puzzle/chapter-06-2/character-6.svg"),
          strokeImgList: [
            {
              type: 1,
              strokeImg: require("@/assets/img/16-GAMES/G21-puzzle/chapter-06-2/character-6-stroke.svg"),
              isShow: false,
            },
          ],
          strokeSum: 0,
          isShow: true,
        },
      ],
    };
  },
  components: {
    DragStrokeToImgGame,
  },
  methods: {
    handleDropEvent(id, type) {
      console.log(id, type);
      this.chineseList.forEach((item) => {
        if (item.id === id) {
          item.strokeImgList.forEach((ele) => {
            if (ele.type === type && !item.flag) {
              if (ele.isShow) return;
              ele.isShow = true;
              item.flag = true;
              console.log(ele.isShow, "+++++++++++++++++++");
              item.strokeSum++;
              this.mapList.forEach((j) => {
                j.forEach((k) => {
                  if (k.id === id) {
                    console.log(k.type);
                    const typeIndex = k.type.findIndex(
                      (value) => value === type
                    );
                    k.type.splice(typeIndex, 1);
                    if (!k.type.length) {
                      k.isAddStyle = true;
                      setTimeout(() => {
                        item.isShow = false;
                      }, 1500);
                      setTimeout(() => {
                        this.answersFoundSum++;
                        if (this.answersFoundSum === this.chineseList.length) {
                          this.isLastStep = true;
                          startConfetti();
                          playCorrectSound();
                        }
                        item.strokeImgList.forEach((obj) => {
                          obj.isShow = false;
                        });
                      }, 1500);
                    }
                  }
                });
              });
            }
          });
          item.flag = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
