var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customlesson layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
        nativeOn: {
          click: function ($event) {
            {
              _vm.numBers != 4 ? _vm.playClick() : null
            }
          },
        },
      }),
      _c("div", { staticClass: "customlesson-content" }, [
        _vm.correctShow
          ? _c("div", { staticClass: "correct-button" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/16-GAMES/G18-maze2/background-controls.svg"),
                  alt: "",
                },
              }),
              _c("div", { staticClass: "correct-button-content" }, [
                _vm.correctOne == 1
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G14-tape-recorder/wrong-answer.svg"),
                        alt: "",
                      },
                    })
                  : _vm._e(),
                _vm.correctOne == 2
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G14-tape-recorder/wrong-answer-hover.svg"),
                        alt: "",
                      },
                    })
                  : _vm._e(),
                _vm.correctTwo == 1
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G14-tape-recorder/correct-answer.svg"),
                        alt: "",
                      },
                    })
                  : _vm._e(),
                _vm.correctTwo == 2
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/16-GAMES/G14-tape-recorder/correct-answer-hover.svg"),
                        alt: "",
                      },
                    })
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        _vm.display
          ? _c("div", { staticClass: "mask-book" }, [
              _c("div", { staticClass: "mask-book-content" }, [
                _vm.buttonHover == 3 && _vm.numBers != 6
                  ? _c("div", { staticClass: "content-bottom-panda" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/05-Panda/panda-correct.gif"),
                          alt: "",
                        },
                      }),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "customlesson-content-text",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.activeClick(1)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "lesson_one",
                        class: { active: "activeType === 1" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.activeClick.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "volume-button",
                          attrs: { src: _vm.volumeImgSrc, alt: "" },
                        }),
                      ]
                    ),
                    _vm.numBers == 2 || _vm.numBers == 6
                      ? _c("span", { staticClass: "font30 pinyin" }, [
                          _vm._v("Nǐ hǎo,wǒ shì Dōngdōng."),
                        ])
                      : _vm._e(),
                    _vm.numBers == 2 || _vm.numBers == 6
                      ? _c(
                          "span",
                          { staticClass: "font3", attrs: { id: "facesOne" } },
                          [_vm._v("你好，我是冬冬。")]
                        )
                      : _vm._e(),
                    _vm.numBers == 4
                      ? _c(
                          "span",
                          { staticClass: "font-pinyin-medium pinyin" },
                          [_vm._v("Nǐ hǎo")]
                        )
                      : _vm._e(),
                    _vm.numBers == 4
                      ? _c(
                          "span",
                          {
                            staticClass: "font-hanzi-medium",
                            attrs: { id: "facesOne" },
                          },
                          [_vm._v("你好")]
                        )
                      : _vm._e(),
                  ]
                ),
                _c("div", { staticClass: "frequency" }),
                _c("div", { staticClass: "content-bottom" }, [
                  _vm.buttonHover > 1
                    ? _c("div", { staticClass: "time-display" }, [
                        _c("span", [_vm._v("00:04")]),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "content-button",
                      on: {
                        mouseover: function ($event) {
                          return _vm.buttonOver(1)
                        },
                        mouseleave: function ($event) {
                          return _vm.buttonLeave(1)
                        },
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.buttonClick(1)
                        },
                      },
                    },
                    [
                      _vm.buttonShow == 1
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/img/16-GAMES/G14-tape-recorder/record.svg"),
                              alt: "",
                            },
                          })
                        : _vm._e(),
                      _vm.buttonShow == 2
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/img/16-GAMES/G14-tape-recorder/record-hover.svg"),
                              alt: "",
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _vm.buttonHover > 1
                    ? _c(
                        "div",
                        {
                          staticClass: "content-button-headset",
                          on: {
                            mouseover: function ($event) {
                              return _vm.buttonOver(2)
                            },
                            mouseleave: function ($event) {
                              return _vm.buttonLeave(2)
                            },
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.buttonClick(2)
                            },
                          },
                        },
                        [
                          _vm.headsetShow == 1
                            ? _c("img", {
                                attrs: {
                                  src: require("@/assets/img/16-GAMES/G14-tape-recorder/listen.svg"),
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                          _vm.headsetShow == 2
                            ? _c("img", {
                                attrs: {
                                  src: require("@/assets/img/16-GAMES/G14-tape-recorder/listen-hover.svg"),
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "content-text" }, [
          _c(
            "div",
            {
              staticClass: "customlesson-content-text",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.activeClick(1)
                },
              },
            },
            [
              _c("span", { staticClass: "font-pinyin-medium pinyin" }, [
                _vm._v("Wǒ yǒu yí ge mèimei"),
              ]),
              _c(
                "span",
                { staticClass: "font-hanzi-medium", attrs: { id: "facesOne" } },
                [_vm._v("我有一个妹妹。")]
              ),
            ]
          ),
        ]),
        _vm.numBers == 1
          ? _c("div", { staticClass: "red-background" }, [
              _c("div", { staticClass: "horn" }),
              _c("span", { staticClass: "font-pinyin-medium pinyin" }, [
                _vm._v("Nǐ hǎo, wǒ shì Dōngdōng."),
              ]),
              _c(
                "span",
                { staticClass: "font-hanzi-medium", attrs: { id: "facesOne" } },
                [_vm._v("你好，我是冬冬。")]
              ),
            ])
          : _vm._e(),
        _vm.numBers == 3 || _vm.numBers == 5
          ? _c("div", { staticClass: "red-answer" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/16-GAMES/G12-map/bubble-nihao.svg"),
                  alt: "",
                },
              }),
            ])
          : _vm._e(),
        _vm.numBers == 1
          ? _c("div", { staticClass: "blue-background" }, [
              _c("div", { staticClass: "horn" }),
            ])
          : _vm._e(),
        _vm.numBers == 4
          ? _c(
              "div",
              {
                staticClass: "customlesson_bottom_left_img",
                on: {
                  mouseover: function ($event) {
                    return _vm.mouseover(1)
                  },
                  mouseleave: function ($event) {
                    return _vm.mouseleave(1)
                  },
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.playClick(2)
                  },
                },
              },
              [
                _vm.hoverClassOne == 1
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/04-Buttons/button-next.svg"),
                        alt: "",
                      },
                    })
                  : _vm._e(),
                _vm.hoverClassOne == 2
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/04-Buttons/button-next-hover.svg"),
                        alt: "",
                      },
                    })
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }