<template>
  <div class="game-container">
    <ClickImgToShowSentenceGame
      :bgImg="bgImg"
      :optionList="optionList"
      :maskImgList="maskImgList"
      @showCoeerctImg="showCoeerctImg"
      :lessonNO="42"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import ClickImgToShowSentenceGame from "@/components/Course/GamePage/ClickImgToShowSentenceGame";
export default {
  data() {
    return {
      titleInfo: {
        hanzi: "（不）喜欢……",
        pinyin: "(bù) xǐhuan…",
      },
      bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-42/background.svg"),
      optionList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-42/dog.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-42/dog.svg"),
          id: 1,
          isShowCorrect: false,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-42/cat.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-42/cat.svg"),
          id: 2,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-42/food.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-42/food.svg"),
          id: 3,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-42/fish.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-42/fish.svg"),
          id: 4,
        },
      ],
      maskImgList: [
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-42/dog-story.svg"),
          id: 1,
          centencePinyin: "Dìdi xǐhuan gǒu.",
          sentenceHanzi: "弟弟喜欢狗。",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-42/cat-story.svg"),
          id: 2,
          centencePinyin: "Mèimei bù xǐhuan māo.",
          sentenceHanzi: "妹妹不喜欢猫。",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-42/food-story.svg"),
          id: 3,
          centencePinyin: "Māma xǐhuan chī píngguǒ.",
          sentenceHanzi: "妈妈喜欢吃苹果。",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-42/fish-story.svg"),
          id: 4,
          centencePinyin: "Bàba xǐhuan chī yú.",
          sentenceHanzi: "爸爸喜欢吃鱼。",
        },
      ],
    };
  },
  components: {
    ClickImgToShowSentenceGame,
  },
  methods: {
    showCoeerctImg(index) {
      this.optionList[index - 1].isShowCorrect = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
