<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :strockNotFoundImage="strockNotFoundImage"
      :strockFoundImage="strockFoundImage"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-42/background-busstop.svg"),
      gameIndex:42,
      strokeImgList: [
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-42/stroke-carlight.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-42/stroke-carlight-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-42/stroke-cloud.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-42/stroke-cloud-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-42/stroke-light.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-42/stroke-light-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-42/stroke-seat.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-42/stroke-seat-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-42/stroke-sign.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-42/stroke-sign-found.svg"),
        },
      ],
      strockNotFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-42/stroke-henggou-pending.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-42/stroke-henggou-found.svg"),
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped>
</style>
