<template>
  <div class="recognition-page-three">
    <PhotoGame
      :imgList="imgList"
      :buttonList="buttonList"
      :titleInfo="titleInfo"
      @changeTheBgImg="changeTheBgImg"
    />
  </div>
</template>

<script>
import PhotoGame from "@/components/Course/GamePage/PhotoGame";
export default {
  data() {
    return {
      imgList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-42/photo-1-1.svg"),
          answerImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-42/photo-1-2.svg"),
          hasClicked: false,
          position: 0,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-42/photo-2-1.svg"),
          answerImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-42/photo-2-2.svg"),
          hasClicked: false,
          position: 1,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-42/photo-3-1.svg"),
          answerImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-42/photo-3-2.svg"),
          hasClicked: false,
          position: 0,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-42/photo-4-1.svg"),
          answerImg: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-42/photo-4-2.svg"),
          hasClicked: false,
          position: 1,
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-42/menu-1.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-42/menu-2.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-42/menu-3.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G04-photo-studio/lesson-42/menu-4.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
      ],
      titleInfo: {
        pinyin: "...ma?",
        hanzi: "……吗？",
      },
    };
  },
  components: {
    PhotoGame,
  },
  methods: {
    changeTheBgImg(index) {
      this.imgList[index - 1].hasClicked = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-three {
  width: 100%;
  height: 100%;
}
</style>
