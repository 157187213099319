<template>
  <div class="game-container">
    <LearnReview
      :imgList="imgList"
      :wordsList="wordsList"
      :sentenceList="sentenceList"
      @deleteWords="handleDeleteWords"
    />
  </div>
</template>

<script>
import LearnReview from "@/components/Course/GamePage/LearnReviewGame";
export default {
  data() {
    return {
      imgList: [
        {
          id: 1,
          imgUrl: require("@/assets/img/18-Blue-review/lesson-42-henggou-yellow.svg"),
          show: true,
          type: 3,
        },
        {
          id: 2,
          imgUrl: require("@/assets/img/18-Blue-review/lesson-42-huan-white.svg"),
          show: true,
          type: 3,
        },
        {
          id: 3,
          imgUrl: require("@/assets/img/18-Blue-review/lesson-42-ma-white.svg"),
          show: true,
          type: 3,
        },
      ],
      wordsList: [
        {
          id: 1,
          name: "xǐhuan",
          namePin: "喜欢",
          show: true,
          type: 1,
        },
        {
          id: 2,
          name: "chī",
          namePin: "吃",
          show: true,
          type: 1,
        },
        {
          id: 3,
          name: "ma",
          namePin: "吗",
          show: true,
          type: 1,
        },
        {
          id: 4,
          name: "píngguǒ",
          namePin: "苹果",
          show: true,
          type: 1,
        },
      ],
      sentenceList: [
        {
          id: 1,
          name: "Nǐ xǐhuan chī píngguǒ ma?",
          namePin: "你喜欢吃苹果吗？",
          show: true,
          type: 2,
        },
      ],
    };
  },
  components: {
    LearnReview,
  },
  methods: {
    handleDeleteWords({ type, id }) {
      if (type === 1) {
        this.wordsList[id - 1].show = false;
      } else if (type === 2) {
        this.sentenceList[id - 1].show = false;
      } else {
        this.imgList[id - 1].show = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
